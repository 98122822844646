//@ts-nocheck
import React, { useState  } from 'react'
import App from './App'
import { ThemeProvider } from '@lxlabs/vc-components/dist/index'

export const LandingPage = () => {
  
  const [activeModel, setActiveModel] = useState(null);

  const update = () => {
    import(`./config/model1`)
      .then(data => 
        setActiveModel(data)
      );
  }

  update();
  
  return (
    <>
      {/* {!activeModel && 
        <>
          <ModelOverlay />
          <ModelLogo src="https://s3.eu-west-2.amazonaws.com/uploads.az-warsaw-rfp.hlxcongress.digital/assets/havas-logo.png" alt=""/>
          <ModelContainer>
            {models.map((model, i) => {
              return (
                <ModelBlockContainer key={i}>
                  <ModelImage src={model.img} alt=""/>
                  <ModelButton onClick={() => update(model.path)} dangerouslySetInnerHTML={{ __html: model.name }}></ModelButton>
                </ModelBlockContainer>
              )
            })}
          </ModelContainer>
        </>
      } */}
      {activeModel && 
        <>
          {/* <CloseButton onClick={() => setActiveModel(null)}>Go back to landing page</CloseButton> */}
          <ThemeProvider theme={activeModel.default.theme}>
            <App sceneConfig={activeModel.default} menu={activeModel.default.menu} />
          </ThemeProvider>
        </>
      }
    </>
  )
}

// const ModelOverlay = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   background: black;
//   opacity: 0;
//   animation: overlay 1.5s;
//   @keyframes overlay {
//     0% { opacity: 1; height: 100%; width: 100%; }
//     50% { opacity: 1 }
//     99% { height: 100%; width: 100%; }
//     100% { opacity: 0; height: 0; width: 0; }
//   }
// `

// const ModelContainer = styled.div`
//   text-align: center;
//   display: flex;
//   align-items: center;
//   justify-content: space-around;
//   flex-wrap: wrap;
//   height: 85vh;
//   margin-top: 10vh;
  
//   @media screen and (min-width: 768px) {
//     margin-top: 15vh;
//   }
// `

// const ModelBlockContainer = styled.div`
//   margin: 32px 16px 0 16px;
//   width: 100%;
  
//   @media screen and (min-width: 768px) {
//     margin: 0 16px;
//     width: 400px;
//   }
// `

// const ModelLogo = styled.img`
//   position: relative;
//   left: 10%;
//   top: 50px;

//   @media screen and (min-width: 768px) {
//     position: absolute;
//   }
// `

// const ModelImage = styled.img`
//   margin: 0 auto;
//   min-width: 100%;
  
//   @media screen and (min-width: 768px) {
//     min-height: 200px;
//     width: 60%;
//     min-width: 400px;
//   }
// `

// const ModelButton = styled.div`
//   background: #2e2940;
//   border-radius: 8px;
//   color: white;
//   padding: 8px 16px;
//   margin: 16px auto;
//   width: 100%;
//   :hover {
//     background: #ff5454;
//     cursor: pointer;
//     transition: background 0.5s;
//   }
// `

// const CloseButton = styled.div`
//   position: absolute;
//   top: 4px;
//   left: 4px;
//   background: #ff5454;
//   color: white;
//   padding: 8px;
//   opacity: 0.2;
//   border-radius: 8px;
//   &:hover {
//     opacity: 1;
//     transition: opacity 0.5s;
//     cursor: pointer;
//   }
// `