//@ts-nocheck
import React, { useState, useRef, Suspense, lazy, useEffect } from 'react'
import './assets/styles/babylon.css'
import {
  Loading,
  ContentSidebar,
  isMobile,
  Navigation,
  StripNavigation,
  Alert,
  gaLogEvent,
  Form,
  EventReducer,
  EventName,
  LeavingDisclaimer,
  styled,
  AudioPlayer
} from '@lxlabs/vc-components/dist/index'
import { useUiActions, useUiState } from './reducers/ui'
import { CONTACTFORM_CONTENT, INTRO_CONTENT, LEAVING_DISCLAIMER } from './config/app'
import * as CONTENT from './config/content'
import { assetsUrl, uploadsUrl } from './config/api'
import { ContentTypes } from './config/content'
import showWarning from './utils/showWarning'

import BlackFade from './components/BlackFade'

import menuBg from './assets/images/menu-bg.png'
import logoSymbol from './assets/images/logo-symbol-white-small.png'
import menuBtn from './assets/images/menu-burger.png'
import havasLogo from './assets/images/havas-logo.png'

import TagManager from 'react-gtm-module'

process.env.REACT_APP_GTM_ID && TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID })

const AppScene = lazy(() => import('./AppScene'))
const { useEventActions, useEventState } = EventReducer

function App({ sceneConfig, menu }) {
  const MOBILE_ROTATION = {};
  const SCREENS = sceneConfig.config.sceneObjects.map(obj => { // eslint-disable-line
    if (obj.circle && obj.mobileRot) {
      MOBILE_ROTATION[obj.circle] = obj.mobileRot
      return obj.circle;
    }
    return undefined;
  }).filter(el => el != null);

  const [content, setContent] = useState<ContentTypes | ''>('')
  const [showLoader, setShowLoader] = useState(true)
  const [blackFadeClass, setBlackFadeClass] = useState('hidden')
  const [leavingDisclaimer, setLeavingDisclaimer] = useState(null)
  const [currentMobileHover, setCurrentMobileHover] = useState(SCREENS[2]);

  const [activeTab, setActiveTab] = useState<string>('')
  const uiState = useUiState()
  const uiSetters = useUiActions()
  const eventState = useEventState()
  const eventSetters = useEventActions()

  const fadeTimer = useRef(null)

  useEffect(() => {
    const scene = document.querySelector("[id^='aad-scene']")
    if (scene) {
      if (uiState.showPopup && isMobile) {
        scene.style.visibility = "hidden"
      } else {
        scene.style.visibility = "visible"
      }
    }

  }, [uiState.showPopup])

  useEffect(() => {
    document.addEventListener('click', (ev) => {
      if (ev.target.tagName === 'A') {
        if (ev.target.dataset.disclaimer !== "false") {
          ev.preventDefault();
          setLeavingDisclaimer({
            href: ev.target.href,
            text: ev.target.innerHTML
          })
        }
        
      }
    })
  }, [])

  const onFlyThroughEnd = (cancel = true) => {

    gaLogEvent({
      event: "action",
      DLV_content_action_type: "flyThroughSkipped",
    })

    fadeInOut(1200)
    setTimeout(() => {
      if (cancel) {
        eventSetters.setEvent(EventName.CANCEL_FLY_THROUGH)
      }
      eventSetters.setFlyThroughActive(false)
      eventSetters.setFlyThroughViewed(true)
    }, 600)
  }

  const onClose = () => {
    uiSetters.setShowPopup(false)
    eventSetters.setEvent(EventName.MOVE_TO_START)
  }

  const showFade = () => {
    setBlackFadeClass('visible')
  }

  const hideFade = () => {
    setBlackFadeClass('click-through')
  }

  const fadeInOut = (time: number) => {
    showFade()
    clearInterval(fadeTimer.current)
    fadeTimer.current = setTimeout(() => {
      hideFade()
    }, time)
  }

  const onNavigationSelect = (instance: string, item) => {
    if (instance === "KEEP_IN_TOUCH") {
      uiSetters.setShowForm(true)
      return
    }

    if (instance === "STUDY_DESIGNS") {
      uiSetters.setAction('STUDY_DESIGNS');
      return;
    }

    if (instance === "EXTERNAL_LINK") {
      setLeavingDisclaimer({
        href: item.linkTitleUrl,
        text: item.contactUsTitle
      })
      return
    }

    if (instance === 'default') {
      onClose();
      setCurrentMobileHover('');
      setTimeout(() => {
        setCurrentMobileHover(SCREENS[2]);
      }, 0)
      
      return
    } 

    uiSetters.setShowForm(false)
    uiSetters.setShowPopup(false)
    uiSetters.setAction('')
    eventSetters.setEvent(EventName.MOVE_TO_OBJECT, instance);
    
  }

  return (
      <Container>
        {sceneConfig.path === `${uploadsUrl}/scenes/room/` && <AudioPlayer src={`${assetsUrl}/audio/BackgroundAudio.wav`} uiState={uiState} maxVolume={0.5} mobileOffsetTop={90}/>}

        {(eventState.flyThroughViewed || sceneConfig.file === 'havas.gltf') && uiState.isReady && <>
          {isMobile ?
            <>
              <StripNavigation
                type={'USAD'}
                skewDegrees={'45deg'}
                headerHeight={90}
                filter={false}
                navItems={menu.menuItems}
                onSelect={onNavigationSelect}
                logo={havasLogo}
                logoWidth={'160px'}
                menuBtn={menuBtn}
                multipleLines={false}
                active={activeTab ? activeTab : 'default'}
                showForm={uiState.showForm}
                menuHandler={(setShowForm, showNav, setShowNav) => {
                  if (!eventState.cameraMoving) {
                    if (uiState.currentAction !== '') {
                      uiSetters.setMobileInitialState(0, [0]);
                      onNavigationSelect('default');
                      setShowForm(false);
                      setShowNav(false);
                    } else {
                      setShowForm(false);
                      setShowNav(!showNav);
                    }
                  }
                }}
                subNavHandler={(instance, subNavIndex, subSubNavIndex, items) => {
                  uiSetters.setMobileInitialState(subNavIndex, [subSubNavIndex]);
                  onNavigationSelect(instance, items);
                }}
                setShowForm={(show) => {
                  if (uiSetters.showForm !== show) {
                    setActiveTab("KEEP_IN_TOUCH")
                  }
                }}
      
              />
            </>
          :
            <>
            {!eventState.cameraMoving &&
              <Navigation
                menuItems={menu.menuItems}
                onSelect={onNavigationSelect}
                loaded={true}
                type={"USAD LITE"}
              />
            }
            </>
          }
        </>}

        {uiState.showPopup && (
          <>
            <ContentSidebar 
              contents={CONTENT[content as ContentTypes]} 
              onClose={onClose}
              clipPath={"polygon(0 12px, 12px 0, 100% 0, 100% 100%, 0% 100%)"} 
              type="USAD LITE"
              initialState={uiState.mobileInitialState}
            />
          </>
        )}
        {eventState.flyThroughActive && <SkipFlyThrough onClick={onFlyThroughEnd}>SKIP</SkipFlyThrough>}
        {showLoader && <Loading isReady={uiState.isReady} content={INTRO_CONTENT} setShowLoader={setShowLoader} />}

        <BlackFade
          onClick={() => {
            if (eventState.flyThroughActive) {
              onFlyThroughEnd()
            }
          }}
          className={`${blackFadeClass}`}
        />
        {uiState.showForm && <Form
          url={process.env.REACT_APP_CONTACT_US_ENDPOINT}
          content={CONTACTFORM_CONTENT}
          showForm={uiState.showForm}
          offset={isMobile ? 90 : 0}
          setShowForm={() => {
            eventSetters.setEvent(EventName.JUMP_TO_START)
            setTimeout(() => {
              uiSetters.setShowForm(false)
            }, 200)
        
          }}
        />}

        <Suspense fallback={<></>}>
          <AppScene
            sceneConfiguration={sceneConfig}
            eventState={eventState}
            eventSetters={eventSetters}
            setActiveTab={setActiveTab}
            setContent={setContent}
            uiState={uiState}
            uiSetters={uiSetters}
            isMobile={isMobile}
            currentMobileHover={currentMobileHover}
            MOBILE_ROTATION={MOBILE_ROTATION}
            onFlyThroughEnd={onFlyThroughEnd}
            showLoader={showLoader}
            hideFade={hideFade}
          />
        </Suspense>
        <Alert
          message="For an optimal experience, please switch your browser to Google Chrome."
          iosMessage="For an optimal experience, please switch your browser to Safari."
          closeText="OK"
          show={showWarning()}
          menuBg={menuBg}
          logoSymbol={logoSymbol}
        />
        {leavingDisclaimer && 
          <LeavingDisclaimer 
            setShowModal={setLeavingDisclaimer} 
            disclaimer={leavingDisclaimer} 
            disclaimerCopy={LEAVING_DISCLAIMER} 
            type={"LITE"}
          />
        }
      </Container>
  )
}

const SkipFlyThrough = styled.p`
  bottom: 5%;
  color: white;
  font-size: 1.25rem;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  transition: opacity 0.5s ease-in-out 0.75s;
  text-shadow: 0 0 4px black;
  z-index: 9999999;
  cursor: pointer;
  :hover {
    color: #f9f9f9;
    font-size: 1.3rem;
  }
`

const Container = styled.div`
  height: 100%;
`

export default App

