export * from './VIDEO';
export * from './BREAST_CANCER_CREATIVE';
export * from './DIGITAL';
export * from './PRINT';
export * from './OVARIAN_CANCER_CREATIVE';

export enum ContentTypes {
  VIDEO = 'VIDEO',
  BREAST_CANCER_CREATIVE = 'BREAST_CANCER_CREATIVE',
  DIGITAL = 'DIGITAL',
  PRINT = 'PRINT',
  OVARIAN_CANCER_CREATIVE = 'OVARIAN_CANCER_CREATIVE',
}

export const contentNavigation = [
  { title: 'VIDEO', instance: ContentTypes.VIDEO },
  { title: 'BREAST_CANCER_CREATIVE', instance: ContentTypes.BREAST_CANCER_CREATIVE },
  { title: 'DIGITAL', instance: ContentTypes.DIGITAL },
  { title: 'PRINT', instance: ContentTypes.PRINT },
  { title: 'OVARIAN_CANCER_CREATIVE', instance: ContentTypes.OVARIAN_CANCER_CREATIVE },
]
