import { contentUrl } from '../api'

const url = `${contentUrl}/video`

export const VIDEO = {
  name: 'VIDEO',
  title: 'VIDEO',
  mobileType: "mobile",
  content: [
    {
      content: {
        contentTemplate: "VIDEOFULLSCREEN",
        content: {
          src: `${url}/rfp-video.mp4`,
          poster: `${url}/rfp-video-poster.png`,
        },
      },
    },
  ],
}
