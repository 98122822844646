import { contentUrl } from '../api'

const url = `${contentUrl}/ovarian_cancer_creative`

export const OVARIAN_CANCER_CREATIVE = {
  name: 'OVARIAN_CANCER_CREATIVE',
  title: 'OVARIAN_CANCER_CREATIVE',
  mobileType: "mobile",
  content: [
    {
      menuTemplate: 'SINGLE',
      menuName: 'Option 1',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${url}/option-1.png`, alt: "Option 1 - Ovarian Cancer Creative" }],
          mobileImageList: [{ srcMobile: `${url}/option-1.png`, alt: "Option 1 - Ovarian Cancer Creative" }],
        },
      }
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'Option 2',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${url}/option-2.png`, alt: "Option 2 - Ovarian Cancer Creative" }],
          mobileImageList: [{ srcMobile: `${url}/option-2.png`, alt: "Option 2 - Ovarian Cancer Creative" }],
        },
      }
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'Option 3',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${url}/option-3.png`, alt: "Option 3 - Ovarian Cancer Creative" }],
          mobileImageList: [{ srcMobile: `${url}/option-3.png`, alt: "Option 3 - Ovarian Cancer Creative" }],
        },
      }
    },
  ],
}
